.form-config-modal {
    box-sizing: border-box;
    margin: 1em;
    width: min(100% - 2em, 420px);
}

.btn-group {
    display: flex;
    gap: 6px;
    margin-top: 1em;
}