/* .responses .form-sections-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
} */

.responses .event-card {
    gap: 1em !important;
    align-self: center;
}
.responses .event-card > h2 {
    color: white;
}

.responses .section-header {
    color: var(--dark-1);
    font-size: 1em;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.responses .section-questions-container {
    display: grid;
    gap: 2em;
}

.responses .question-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.responses .answer-order-container {
    display: grid;
    gap: 2em;
}
.responses .answer-order-header {
    padding-bottom: 0.5em;
}




.responses .question-answers-container {
    display: grid;
    gap: 1em;
    
    margin-top: 0.5em;
}

.responses .dark-card.background-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}


.responses .answer {
    display: grid;
    grid-template-areas: 'answer';
    overflow: hidden;
    
    gap: 0;
    padding-bottom: 0;
}
.responses .answer-details {    
    grid-area: answer;
    overflow: hidden;
    display: grid;
    gap: 0;
    padding-bottom: 1em;
}
.responses .answer .points {
    grid-area: answer;
    justify-self: end;
    align-self: start;
    display: flex;
    gap: 0.5em;
    align-items: center;
    padding-bottom: 1em;
}

.driver-answer {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    /* padding: 0 0.5em; */
}

.driver-answer h3 {
    /* font-size: 1.5em; */
    line-height: 1em;
}


.responses .submission-avatars {
    grid-area: responses;
    display: flex;
    padding-top: 0.5em;
    border-top: 1px solid var(--dark-2);
    /* border-bottom-left-radius: var(--standard-radius);
    border-bottom-right-radius: var(--standard-radius); */

    /* justify-self: start; */
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
}

.responses .submission-avatars:hover {
    background-color: rgba(170, 170, 170, 0.8);
    cursor: pointer;
}

.answer-responses-container {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 4px;
}


.tiny-poster {
    width: 48px;
    height: 64px;
    object-fit: cover;
    border-radius: 4px;
}

.tiny-poster:hover {
    cursor: pointer;
}