/* .schedule {
    background: linear-gradient(#170F33 300px, rgba(14, 14, 14, 0) 800px);
} */



.highlight-event {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 1px 3px #151515, 0 1px 2px #151515;
    border: 1px solid var(--dark-1);
    border-radius: var(--standard-radius);
    margin: 1em 0;
    padding: 1em;
    height: 400px;
    /* mix-blend-mode: hard-light; */
    color: white;
    text-shadow: 0px 2px 6px rgb(0 0 0 / 1);

    background-size: cover;
    justify-content: flex-end;
    background-position: center center; 
}

.highlight-event:hover {
    cursor: pointer;
}

.highlight-event h2 {
    font-weight: bold;
    margin: 0;
    max-width: 100%;
}

.highlight-event > div {
    text-align: center;
}


.event-list {
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin-top: 2em;
    min-height: 70dvh;
}

.event {
    display: grid;
    grid-template-columns: 90px;
    grid-template-areas: 
        'time name'
        'time round'
        'time locale'
        'time circuit';
    gap: 0.5em;
    align-items: start;    
    border: 1px hidden var(--light-1);
    mix-blend-mode: hard-light;
    text-shadow: 0px 2px 6px rgb(0 0 0 / 0.6);
}


.event:hover {
    border: 1px solid var(--light-1);
    border-radius: var(--standard-radius);
    cursor: pointer;

    /* background-color: rgba(28, 28, 28, 0.5); */
    backdrop-filter: blur(10px);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    
    margin: -0.5em -0.5em;
    padding: 0.5em 0.5em;
}


.event .time-container {
    grid-area: time;
    width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5em 0;

    border-radius: var(--standard-radius);
    background-color: rgba(28, 28, 28, 0.5);
    backdrop-filter: blur(10px);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.event .time-container .weekday,
.event .time-container .timezone {
    font-size: 0.8em;
}

.event .time-container .date {
    font-weight: 600;
    margin-bottom: 4px;
}


.event .event-name {
    grid-area: name;
    font-weight: bold;
    margin-bottom: 0;
    padding: 0.2em;
}

.event .locality-container {
    grid-area: locale;
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.event .locality-container > * {
    flex-shrink: 0;
}
.flag {
    height: 1.5em;
    aspect-ratio: 3/2;
    border-radius: 4px;
    object-fit: cover;
    box-shadow: 0px 3px 4px rgb(0 0 0 / 0.4);
}
.flag.small {
    height: 1em;
}

.event .circuit-container {
    grid-area: circuit;
    display: flex;
    align-items: center;
    gap: 0.5em;
}
.circuit-map {
    height: 3em;
    aspect-ratio: 1;
    filter: invert() drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    display: block;
    object-fit: contain;
}
.circuit-map.small {
    height: 2em;
}


.event .round-container {
    grid-area: round;
    display: flex;
    gap: 1em;
    align-items: flex-end;
}

@media (min-width: 680px) {
    .event {
        grid-template-areas: 
            'time name name round'
            'time locale circuit round';
        gap: 1em;
        grid-auto-columns: max-content max-content 1fr max-content;
    }
    .event .event-name {
        margin-bottom: -1em;
    }
    .event .locality-container {
        align-self: center;
    }
    .event .circuit-container {
        align-self: center;
    }
    .event .round-container {
        width: 90px;
        flex-direction: column;
        gap: 0.5em;
        margin-top: 0.5em;
        justify-self: flex-end;
    }
    .event .round-container > * {
        flex: 0;
    }
}


.no-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sprint-label {
    font-size: .8rem;
    padding: 0em 1em;
    color: rgb(112, 101, 1);
    background: rgb(248,255,157);
    background: 
        linear-gradient(100deg, 
            rgba(248,255,157,0) 5%, 
            rgba(248,255,157,1) 15%, 
            rgba(248,255,157,1) 85%, 
            rgba(248,255,157,0) 95%) padding-box,
        linear-gradient(100deg, 
            rgba(112, 101, 1, 0) 5%, 
            rgba(112, 101, 1,1) 15%, 
            rgba(112, 101, 1,1) 85%, 
            rgba(112, 101, 1,0) 95%) border-box;
    border: 2px solid transparent;
}

.event-action {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    gap: 10px;
    align-self: center;
}

.past-schedule {
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    padding-top: calc(64px + 1em);

    flex-grow: 1;
    overflow: auto;
}

.schedule-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    padding: 0 1em;
}
.schedule-header .chip {
    background-color: var(--grey);
}
.past-event-list {
    display: grid;
    gap: 1em;
    margin-top: 2em;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.past-event {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    border: 1px solid var(--dark-1);
    border-radius: var(--standard-radius);
    margin: 1em;
    padding: 1em;
    /* height: 400px;
    width: 250px; */
    aspect-ratio: 2.5 / 3;
    text-shadow: 1px 1px 4px var(--dark-2);

    background-position: center center; 
    background-size: cover;
}

.past-event:hover {
    cursor: pointer;
}

/* .page-container.schedule {
    padding-bottom: 500px;
} */


.end-of-season {
    /* position: absolute; */
    /* bottom: 0.5em;
    left: 0; */
    flex-grow: 1;
    height: 15vh;
    width: calc(100dvw - 0.5em);

    background-image: 
        radial-gradient(ellipse at bottom, rgba(19, 19, 19, 0.5) 30%, var(--dark-4) 60%), 
        url('https://wus1saformulafantasy.blob.core.windows.net/formulafantasy-artifacts/EndOfSeason.jfif');
    background-size: cover;
    background-position: 0% 65%; 

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    font-weight: 800;
    text-shadow: 1px 1px 4px var(--dark-2);
}

.schedule-empty-state {
    height: 300px;
    width: min(100%, 400px);
    background-image: 
     
        url('https://wus1saformulafantasy.blob.core.windows.net/formulafantasy-artifacts/empty_track.jpg');
    background-size: cover;
    background-position: 0% 65%; 
    border-radius: var(--standard-radius);

    display: grid;
    justify-content: center;
    margin: 0 auto;
}

.schedule-empty-state > h1 {
    mix-blend-mode: difference;
    padding: 0 auto;
    text-align: center;
}