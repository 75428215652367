:root {
  --dark-5: #000000;
  --dark-4: #0E0E0E;
  --dark-3: #1C1C1C;
  --dark-2: #29292B;
  --dark-1: #373739;
  --grey:   #454547;
  --light-1: #6A6A6C;
  --light-2: #8F8F91;
  --light-3: #B5B5B5;
  --light-4: #DADADA;
  --light-5: #FFFFFF;
  --font-color: #FFFFFF;
  --f1-red: #E81E02;
  --hyperlink: #0080FF;
  --primary: #0080FF;
  --standard-radius: 1em;
  --small-radius: 0.5em;
  --form-control-accent: #0080ff81;
  --form-control-accent-border: #0080FF;
  --form-control-accent-disabled: #105392;
}
* {
  box-sizing: border-box;
}
.App {
  background-color: var(--dark-4);
  height: 100dvh;
  color: var(--light-4);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

h1, h2, h3, h4, h5 {  
  /* color: #eee; */
}

h1 {
  font-size: 2em;
  font-weight: 800;
}

h2 {
  font-size: 1.5em;
  font-weight: 800;
  margin: 0;
}

h3 {
  font-size: 1.2em;
  font-weight: 800;
  margin-bottom: 0;
}

h4 {
  margin: 0;
}

p {
  margin: 0;
}

.subtitle {
  display: flex;
  font-size: .8rem;
  gap: 0.5em;
  align-items: center;
  font-weight: 400;
}

.main-content {
  overflow: hidden;
  display: flex;
  flex-grow: 1;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding: 1em;
  overflow: auto;
  padding-top: calc(64px + 1em);
}

@media (min-width: 680px) {
    .page-container {
      justify-content: unset;
      align-items: stretch;
    }
    /* .page-container > * {
        animation: shift 0.15s forwards ease;
    } */
}

@keyframes shift {
  from { width: 100%; }
  to { width: 660px; }
}

.subtle-frost {
  background-color: rgba(28, 28, 28, 0.8);
  backdrop-filter: blur(10px);
  color: var(--light-3);
}

.dark-card {
  display: flex;
  flex-direction: column;
  gap: 1em;
  overflow: hidden;
  flex-shrink: 0;
  
  width: min(100%, 600px);
  border: 1px solid var(--dark-2);
  border-radius: var(--standard-radius);
  background-color: rgba(28, 28, 28, 0.5);
  /* background-color: var(--dark-3); */

  /* backdrop-filter: blur(10px); */
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.dark-card h2 {
  color: var(--grey);
}
.dark-card h3 {
  margin: 0;
}
.dark-card.info {
  font-size: 0.8em;
  color: var(--light-3);
}
.dark-card.full-width {
  width: 100%;
}
.dark-card.scrollable {
  overflow: auto;
}
.dark-card.selectable:hover {
  cursor: pointer;
  border: 1px solid var(--light-5);
  box-shadow: 0 4px 12px rgba(170,170,170,0.15), 0 6px 10px rgba(170,170,170,0.12);
}
.dark-card.selected {
  border: 1px solid var(--form-control-accent-border);
  box-shadow: 0 8px 16px rgba(0,128,255,0.15), 0 6px 10px rgba(0,128,255,0.12);
  /* background-color: var(--form-control-accent); */
}
.dark-card {
  padding-inline: 0;
}
.dark-card > * {
  padding-inline: 1.5em;
}
.dark-card > *:first-child {
  padding-top: 1.5em;
}
.dark-card > *:last-child {
  padding-bottom: 1.5em;
}

@media (max-width: 680px) {
  /* .dark-card {
    padding: 0.8em 1em;
  } */
  .dark-card > * {
    padding-inline: 1em;
  }
  .dark-card > *:first-child {
    padding-top: 0.8em;
  }
  .dark-card > *:last-child {
    padding-bottom: 0.8em;
  }
}

.padding-0 {
  padding: 0;
}
.padding-05 {
  padding: 0.5em;
}
.padding-bottom-0 {
  padding-bottom: 0;
}
.single-line {
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
}

.page-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin-top: 0.5em;
  margin-bottom: 1em;
}
.page-header h2 {
  text-align: center;
}
/* .page-header > * {
  flex-grow: 1;
} */

.hyperlink {
  color: var(--hyperlink);
  text-decoration: underline;
}

.hyperlink:hover {
  cursor: pointer;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.background-image-cover {
  background-size: cover;
  background-position: center center;
  height: 10em;
}

.background-image-contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

/* Inputs */

.form-field {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
input {
  color: #fff;
  background-color: var(--dark-3);
  border-radius: var(--standard-radius);
  border: 1px solid var(--dark-1);
  line-height: 3em;
  padding: 0 1em;
  font-size: 1em;
  width: 100%;
}

input:not([type="radio"]) {
  box-shadow: inset rgba(0,0,0,0.4) 0px 0px 30px 0px; 
}

input:disabled {
  background: #444;
  color: var(--light-1);
  cursor: not-allowed;
}

input[type="radio"]:checked:disabled {
  background: var(--form-control-accent-disabled);
}

input::placeholder {
  font-style: italic;
}


input[type="radio"]:hover {
  cursor: pointer;
}
/* https://moderncss.dev/pure-css-custom-styled-radio-buttons/ */
input[type="radio"] {
  appearance: none;
  margin: 0;
  padding: 3px;
  /* font: inherit;
  color: currentColor; */
  width: 1.15em;
  height: 1.15em;
  /* border: 0.15em solid var(--dark-2); */
  border-radius: 50%;
  /* transform: translateY(-0.075em);
  display: grid;
  place-content: center; */
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transform: translateY(-0.075em);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(#fff);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
input[type="radio"]:checked {
  border: 0.15em solid var(--dark-2);
  background-color: var(--form-control-accent);
}


/* Bootstrap */
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex.justify-content-between {
  justify-content: space-between;
}
.flex.justify-content-start {
  justify-content: flex-start;
}
.flex.justify-content-center {
  justify-content: center;
}
.flex.justify-content-end {
  justify-content: flex-end;
}
.flex.align-items-start {
  align-items: flex-start;
}
.flex.align-items-center {
  align-items: center;
}
.flex.align-items-end {
  align-items: flex-end;
}
.flex.justify-self-end {
  justify-self: end;
}
.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid {
  display: grid;
}
.grid.justify-items-start {
  justify-items: start;
}
.align-self-center {
  align-self: center;
}

.gap-0 {
  gap: 0;
}
.gap-1 {
  gap: 1em;
}
.gap-05 {
  gap: 0.5em;
}


.text-align-left {
  text-align: left !important;
}
.text-align-center {
  text-align: center;
}

.margin-inline-auto {
  margin-inline: auto;
}


textarea {
  color: #fff;
  background-color: #202124;
  border-radius: var(--standard-radius);
  border: 2px solid #777;
  padding: 0 10px;
}

textarea:disabled {
  background: #444;
  cursor: not-allowed;
}
textarea::placeholder {
  font-style: italic;
}

.divider {
    border-top: 1px solid #555;
    margin: 1em 0 !important;
    width: 100%;
}

.transparent {
  color: transparent;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

.loginModal {
  border-radius: var(--standard-radius);
  background-color: #212121 !important;
  color: white;
}
.loginOverlay {
  backdrop-filter: blur(10px) brightness(60%);
}
.modelCloseIcon {
  fill: white;
}




ul.breadcrumb {
  margin: 0;
  padding: 0.5em 1em;
  list-style: none;
  display: flex;
}

ul.breadcrumb li {
  display: flex;
  align-items: center;
  font-size: 0.8em;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li+li:before {
  padding: 0.5em;
  content: "/\00a0";
  margin-left: 0.5em;
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
  color: #0275d8;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}

.back-btn {
  cursor: pointer;
}

.back-btn > svg {
  height: 0.7em;
  width: 0.7em;
}

.back-btn > span {
  padding-bottom: 2px;
  margin-left: 4px;
}


/* ------------------------------- */
/* .chip {
  font-size: 0.8em;
  display: flex;
  border-radius: 2em;
  padding: 0 0.5em !important;
  height: 100% !important;
  background-color: var(--dark-1);
  color: var(--light-5);
  border: 1px solid var(--light-1);
  flex-grow: 0;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.answer-chip {
  background-color: #333;
  color: white;
  font-weight: bold;
  padding: 5px 8px;
  border-radius: 8px;
  display: flex;
}

.answer-chip.correct {
  color: rgb(89, 187, 89) !important;
  background-color: rgb(41, 62, 41) !important;
}
.answer-chip.partially-correct {
  color: rgb(187, 176, 89) !important;
  background-color: rgb(62, 61, 41) !important;
}
.answer-chip.incorrect {
  color: rgb(136, 136, 136) !important;
  background-color: rgb(59, 59, 59) !important;
}

.answer-chip .driverCode {
  background-color: white;
  color: #333;
  font-weight: bold;
  margin-left: 5px;
  padding: 0 5px;
  border-radius: 5px;
}


.answer-chip.correct .driverCode {
  color: rgb(41, 62, 41);
  background-color: rgb(89, 187, 89);
}

.answer-chip.partially-correct .driverCode {
  color: rgb(62, 61, 41);
  background-color: rgb(187, 176, 89);
}

.answer-chip.incorrect .driverCode {
  color: #333;
  background-color: rgb(136, 136, 136);
}



/* ------------------------------- */

.loader-show {
  position: absolute;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100dvw !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px) brightness(90%);
  z-index: 100;
}

.loader-hide {
  display: none;
}
.spinner-img {
  animation: spin 1s linear infinite;
  width: 75px;
}
@keyframes spin { 100% { transform:rotate(360deg); } }




/*------------------------------ */

.table-container {
  overflow: auto;
  width: 100% !important;
  margin-bottom: 1em;
}

.table {
  border-collapse: collapse;
  border-width: 0;
  color: white;
  border-spacing: 0;
  margin: 0 auto;
  border-radius: var(--standard-radius);
  width: 100%;
  overflow: hidden;
}

.table thead { 
  position: sticky; 
  top: 0; 
  /* background-color: var(--dark-2); */
  border-bottom: 2px solid gray;
  z-index: 1;
  color: var(--light-1);
  font-style: italic;
}

.table tr {
  border-bottom: 1px solid gray;
  padding: 0 1em;
}

.table td, 
.table th {
  padding: 4px;
  margin: 0;
  text-align: center;
}

.table .td-4em {
  width: 4em;
  /* overflow: hidden;
  display: inline-block;
  white-space: nowrap; */
}

/* .table .td-6em {
  width: em;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
} */

.table input {
  padding: 0 0.5em;
}