.submission {
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 1em;
    /* margin-bottom: 1em; */
}
/* .submission .section-header,
.submission .question-header {
    position: sticky;
} */

/* @media (min-width: 680px) {
    .submission .dark-card.section > * {
        margin: 0 1em;
    }
} */
.submission .section {
    flex-shrink: 1;
}
.submission .section-header {
    color: var(--dark-1);
    font-size: 1em;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.submission .question-container {
    gap: 1em;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 0;
}

.submission .question {
    display: flex;
    flex-direction: column;
    margin: 0;
    /* padding: 0 1em; */
    overflow: auto;
}



.submission .question > * {
    padding: 0 1em;
}

.submission .question-answer-container {
    gap: 1em;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.submission .answer {
    display: grid;

    grid-template-areas: 'input details';
    grid-template-columns: 1em auto;
    gap: 0;
    column-gap: 0.5em;
    
    padding: 0.5em;
}

.submission .input {
    grid-area: input;
    display: grid;
    justify-content: space-between;
    align-items: center;
    place-self: center;
}



.answer-image {
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: var(--standard-radius);
    flex-shrink: 0;
}

.answer-image.medium {
    width: 64px;
    height: 64px;
}

.answer-image.small {
    width: 48px;
    height: 48px;
}


.submission .bonus-radio-details {
    grid-area: details;

    display: flex;
    gap: 1em;
    justify-content: space-between;

}






.podium-picks-pictures {
    display: flex;
    justify-content: flex-end;
    padding: 0.5em 1em;
    gap: 5px;
}
.podium-picks-pictures > div {
    width: 2em;
}

.podium-picks-question {
    display: flex;
    justify-content: space-between;
    padding: 0.5em 0;
    height: 1.5em;
    flex-shrink: 0;
    position: sticky;
    top: 0;
    
    /* background-image: linear-gradient(to bottom, 
        var(--dark-3)  80%, 
        rgba(255,0,0,0)
    ); */
}
.podium-picks-answer {
    display: flex;
    justify-content: space-between;
    padding: 0;
    align-items: flex-start;
    gap: 1em;
}

.podium-picks-answer > label {
    display: flex;

    /* https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout */
    min-width: 0;  /*needed since child has whitespce nowrap */
}
.podium-picks-question .podium-picks-selections {
    padding-top: 0;
    padding-bottom: 0;
}
.podium-picks-selections {
    display: flex;
    justify-content: flex-end;
    gap: 0.5em;
}
.podium-picks-selections > * {
    width: 1.2rem;
    padding: 0;
    margin: 0;
}


.podium-picks-answer .driver-name {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    /* margin: 0 1em; */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}






.form-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 1em; */
}
.form-nav > div:first-child,
.form-nav > div:last-child
 {
    flex-grow: 1;
    display: flex;
    flex-basis: 0;  
}

.form-nav > div:first-child {
    justify-content: flex-end;
}
.form-nav-label {
    display: flex;
    flex-basis: 3em;
    justify-content: center;
}