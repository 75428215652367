.constructor .main {
    display: grid;

    grid-template-areas: 'details';
    grid-template-columns: auto;
    gap: 0;
    
    padding: 0.5em;
}

.constructor[variant|="input"] .main {
    grid-template-areas: 'input details';
    grid-template-columns: 1em auto;
    column-gap: 0.5em;
}


.constructor .input {
    grid-area: input;
    display: grid;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* height: 100%; Will break layout in Safari */
    place-self: center;
}

.constructor .constructor-details {
    grid-area: details;
    display: grid;
    align-self: center;
    /* padding-bottom: 0.5em; */
    align-items: center;
    text-shadow: 2px 2px 8px var(--dark-2);
}

.constructor .stats {
    grid-area: details;
    justify-self: end;
    align-self: start;
    display: flex;
    gap: 0.5em;
    align-items: center;
    padding-bottom: 1em;
}

.constructor .stats-accordian {
    grid-area: stat-accordian;
    transition: all 0.3s ease; /* Smooth transition */
    max-height: 0; /* Initially hidden */
    overflow: hidden;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    padding-block: 0;
}
.constructor .stats-accordian.open {
  max-height: 600px; /* Adjust as needed */
  padding-block: 1em;
  border-top: 1px solid var(--dark-2);
}
@media (max-width: 680px) {
  .constructor .stats-accordian {
    grid-template-columns: 1fr;
  }
}