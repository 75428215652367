.chip {
    font-size: 0.8em;

    padding: 0 1em;
    line-height: 2em;

    /* border: 1px solid var(--dark-2); */
    border: 1px solid rgba(32,32,32,0.25);
    border-radius: 100px;
    /* background-color: var(--dark-1); */
    background-color: rgba(80,80,80,0.25);
    
    backdrop-filter: blur(10px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);
}