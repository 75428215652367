

.review .event-card {
    gap: 1em !important;
}
.review .event-card > h2 {
    color: white;
}

.review .section-header {
    color: var(--dark-1);
    font-size: 1em;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.review .section-questions-container {
    display: grid;
    gap: 2em;
}

.review-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    overflow: auto;
    align-items: center;
}

.review .question-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.review .question-answers-container {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    
    margin-top: 0.5em;
}

.review .answer {
    display: grid;
    /* padding: 0.5em; */
    gap: 1em;
    
    grid-template-areas: 
        'answer';
}

.review .answer .answer-detail {    
    grid-area: answer;
    overflow: hidden;
    display: grid;
}
.review .answer .points {
    grid-area: answer;
    justify-self: end;
    align-self: start;
    display: flex;
    gap: 0.5em;
    align-items: center;
}

.review-error > p {
    margin: 0;
}

.validation-failed {
    color: rgb(205, 0, 0);
}

.form-nav-subtext {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 2em; */
    color: #777;
}