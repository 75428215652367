.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    height: 100dvh;
    width: 100dvw !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 102;
    
    background-color: rgba(0,0,0,0);
    backdrop-filter: blur(0);
    opacity: 0;
    visibility: hidden;
    transition: 
        background-color 0.2s ease,
        backdrop-filter 0.2s ease,
        opacity 0.2s ease,
        visibility 0.2s ease;
}


.modal-backdrop.show {
    background-color: rgba(0,0,0,0.5);
    backdrop-filter: blur(8px);
    display: flex;
    opacity: 1;
    visibility: visible;
}
.modal {
    display: flex;
    flex-direction: column;
    padding: 1em;
    margin: 1em;
    flex-grow: 0;

    background-color: var(--dark-2);
    border: 1px solid var(--dark-1);
    border-radius: var(--standard-radius);
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    
    max-width: 95dvw;
    min-width: 180px;
    max-height: 90dvh;
    overflow:auto;
    z-index: 100;
    
}

.modal-header h2 {
    margin-top: 0;
    margin-bottom: 0.5em;
}

.modal label {
    color: white;
}

.modal-content {
    overflow: auto;
}
.modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 0.2em;
    padding-top: 0.5em;
}
