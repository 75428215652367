.ff-dropdown-container {
    position: relative;
}
.ff-dropdown {
    cursor: pointer;

    display: flex;
    padding: 0 0.5em;
    padding-left: 1em;
    line-height: 1.8em;
    border-radius: var(--standard-radius);
    background-color: var(--dark-2);
    align-items: center;
}

.ff-dropdown-menu {
    opacity: 0;
    visibility: hidden;
    position: absolute;

    margin-top: 1em;
    border-radius: var(--standard-radius);
    top: 1.2em;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 80;
}
.ff-dropdown-menu.show {
    opacity: 1;
    visibility: visible;
}

.ff-dropdown-item {
    padding: 0 1em;
    background-color: var(--dark-2);
    line-height: 2em;
    height: 2em;        /* for empty text options*/
}
.ff-dropdown-item:hover {
    cursor: pointer;
    background-color: var(--grey);
}

.ff-dropdown-item:first-of-type {
    border-radius: var(--standard-radius) var(--standard-radius) 0 0;
}

.ff-dropdown-item:last-of-type {
    border-radius: 0 0 var(--standard-radius) var(--standard-radius);
}