.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
}

.form-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    top: 0;
}

.settings-container {
    background-color: rgb(1, 68, 68);
    padding: 8px;
    border-radius: var(--standard-radius);
}
.form-content {
    overflow: auto;
    display: flex;
    flex-direction: column;
}
.label-bar {
    display: flex;
    align-items: center;
}
.label-text {
    color: #777;
    margin: 0;
    margin-right: 0.5em;
    flex-grow: 0;
}
.label-adorner {
    font-size: 0.8em;
    color: #777;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form ul {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 30px;
    margin-top: 0;
}

.form li {
    display: list-item;
}
.form input {
    border: none;
}
.form input:hover {
    border: 2px solid #777;
}
.form textarea {
    border: none;
    margin-bottom: 5px;
}
.form textarea:hover {
    border: 2px solid #777;
}

.input-section {
    font-size: 1.5rem;
}
.input-question,
.input-answer {
    font-size: 1rem;
}


.form-actions {
    display: flex;
}

.form-state-btn {
    border-radius: var(--standard-radius);
    border: 1px solid #777;
    padding: 0.5em;
}
.form-state-btn:hover {
    cursor: pointer;
}

.form .dark-card {
    overflow: initial;
}

.form .section-header {
    display: flex;
    flex-direction: column;
    margin: 1em 0;
}

.form .section-header h2 {
    margin-bottom: 0;
}

.delete-btn {
    display: inline;
    margin-left: 0.5em;
    cursor: pointer;
}

.form label,
.form input {
    display: flex;
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.form .question-container,
.form .question-answer-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 1em;
}

.form .question {
    display: flex;
    flex-direction: column;
}
.form .answer {
    display: flex;
    flex-direction: column;
    margin: 0;
    border-left: 4px solid var(--grey);
    border-right: 4px solid var(--grey);
    border-radius: var(--standard-radius);
    padding: 0 1em;
    gap: 4px;
}

.answer-dropdown {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: var(--standard-radius);
    padding: 0.5em 1em;
}
.answer-dropdown:hover {
    background-color: var(--dark-2);
}
.answer-dropdown input {
    flex-grow: 0;
}
.answer-dropdown:has(input[type=radio]:checked) {
    background-color: rgb(33, 60, 134);
}

.answer-image {
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: var(--standard-radius);
}


/* --------------- */

.adder {
    margin: 1em;
    display: flex;
    align-items: center;
}
.adder:hover {
    cursor: pointer;
}

.adder-line {
    height: 1px;
    margin: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    color: #eee;
}

.adder:hover .adder-line {
    background-color: #777;
}

.adder-label {
    display: flex;
    align-items: center;
    color: #999;
    background-color: #333333;
    border-radius: 999em; /*40px 999em 999em 40px;*/
    height: 20px;
    text-align: center;
    font-size: 0.8em;
    line-height: 1.1em;
    padding: 0 10px;
    margin-bottom: 1px;
}


/* --------------- */

.description-toggle {
    display: flex;
    align-items: center;
    margin: 0.2em 0;
}
.toggle {
    --width: 40px;
    --height: calc(var(--width) / 2);

    display: flex;
    cursor: pointer;
    margin-left: 1em;
}

.toggle .slider {
    width: var(--width);
    height: var(--height);
    border-radius: var(--height);
    background-color: #ccc;
    transition: all 0.4s ease-in-out;
}

.toggle .slider::before {
    content: '';
    display: block;
    width: var(--height);
    height: var(--height);
    border-radius: calc(var(--height) / 2);
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease-in-out;
}

.toggle input {
    display: none;
}
.toggle input:checked+.slider {
    background-color: #2196F3;
}

.toggle input:checked+.slider::before {
    transform: translateX(calc(var(--width) - var(--height)));
}



