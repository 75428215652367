.admin-page {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-top: calc(64px + 2em);
}

.user-form {
    margin: 0 auto;
    padding: 1em;
}
.user-table {
    width: 100%;
    border-collapse: collapse;
}
.user-table td, .user-table th {
  border: 1px solid#333;
  padding: 0.2em 1em;
}

.user-form input {
    width: 100%;
    color: #fff;
    background-color: #202124;
    border-radius: var(--standard-radius);
    border: 2px solid #777;
    padding: 0 10px;
}